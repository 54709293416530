import type { PropsWithChildren } from 'react'

export interface CardDetailProps extends PropsWithChildren {
  /**
   * Title for the card detail section.
   */
  title: string
}

export const CardDetail: React.FC<CardDetailProps> = ({ title, children }) => {
  return (
    <div
      className="w-full space-y-1 text-neutral-subtle"
      data-testid="card-detail"
    >
      <h3 className="type-body-xs-bold">{title}</h3>
      <div className="type-body-sm">{children}</div>
    </div>
  )
}
