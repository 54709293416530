'use client'

import { useCallback } from 'react'

import { Button } from '@features/common.components/Button'

import { Icon } from '../../Icon'
import { usePlaygroundContext } from '../PlaygroundProvider'

import type { ViewVariants } from '../PlaygroundProvider'

export const ActionBar = () => {
  const [
    {
      config: { view },
    },
    { switchView },
  ] = usePlaygroundContext()

  const changeView = useCallback(
    (view: ViewVariants) => () => {
      switchView(view)
    },
    [switchView],
  )

  if (view === 'Overview') {
    return (
      <>
        <Button
          className="md:hidden"
          onClick={changeView('Snippets')}
          variant="text"
        >
          <div>view code</div>
        </Button>
        <Button className="[&>*]:space-x-2" type="submit" variant="primary">
          <div>Try it out</div>
          <Icon className="h-6 w-6" name="ArrowRightIcon" />
        </Button>
      </>
    )
  }

  return (
    <Button
      className="[&>*]:space-x-2"
      onClick={changeView('Overview')}
      variant="secondary"
    >
      <Icon className="h-6 w-6" name="ArrowLeftIcon" />
      <div>Back To Inputs</div>
    </Button>
  )
}
