'use client'

import { Card } from '@circlefin/components/lib/Card'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { CardDetail } from '../CardDetail'
import { CWButton } from '../ComponentsWeb/Button'
import {
  CWHeading,
  CWHeadingHeader,
  CWHeadingIntro,
} from '../ComponentsWeb/Heading'
import { CWIcon, CWIconCircle } from '../ComponentsWeb/Icon'

import type { IconCircleProps } from '@circlefin/components/lib/Icon'

export interface SdkSampleCardProps {
  /**
   * Title for the card.
   */
  title: string
  /**
   * Description for the card.
   */
  description: string
  /**
   * Name of the icon on the card.
   */
  iconName: IconCircleProps['name']
  /**
   * Color of the icon on the card.
   */
  iconColor: IconCircleProps['intent']
  /**
   * Text for the product detail section of the card.
   */
  textForProduct: string
  /**
   * Text for the languages detail section of the card.
   */
  textForLanguages: string
  /**
   * Text for the features detail section of the card.
   */
  textForFeatures?: string
  /**
   * The supported platforms to display on the card.
   */
  platforms?: ('ios' | 'android' | 'react' | 'web')[]
  /**
   * The relevant links to display on the card.
   */
  relevantLinks: {
    /**
     * The type of link, can be `undefined`, `'github'`, or `'npm'`. Used to display an icon in the link button.
     */
    type?: 'github' | 'npm'
    /**
     * The label for the link button.
     */
    label: string
    /**
     * The url for the link button.
     */
    url: string
  }[]
  /**
   * The navigation anchor for the card.
   */
  anchor?: string
}

export const SdkSampleCard: React.FC<SdkSampleCardProps> = ({
  title,
  description,
  iconName,
  iconColor,
  textForProduct,
  textForLanguages,
  textForFeatures,
  platforms,
  relevantLinks,
  anchor,
}) => {
  const { t } = useTranslation('common')

  return (
    <Card
      className="w-full p-5 flex flex-col gap-4"
      data-testid="sdk-sample-card"
      id={anchor}
    >
      {/* Heading */}
      <CWHeading data-testid="sdk-sample-card-heading" variant="title/md">
        <div className="flex flex-row items-center gap-4">
          <CWIconCircle
            className="shrink-0"
            intent={iconColor}
            name={iconName}
          />
          <div>
            <CWHeadingHeader className="type-h-title-md">
              {title}
            </CWHeadingHeader>
            {/* Note that the description is used twice but each one is hidden on different screen sizes because the placement of the description must completely change between screen sizes. */}
            <CWHeadingIntro className="type-body-base hidden sm:block">
              {description}
            </CWHeadingIntro>
          </div>
        </div>
        <CWHeadingIntro className="type-body-base mt-4 sm:hidden">
          {description}
        </CWHeadingIntro>
      </CWHeading>

      {/* Product, Languages, Platforms */}
      <div className="flex flex-row flex-wrap gap-4 w-fit">
        <div data-testid="sdk-sample-card-product">
          <CardDetail title={t`sdkSampleCard.product`}>
            {textForProduct}
          </CardDetail>
        </div>
        <div data-testid="sdk-sample-card-languages">
          <CardDetail title={t`sdkSampleCard.languages`}>
            {textForLanguages}
          </CardDetail>
        </div>
        {platforms && platforms.length > 0 && (
          <div data-testid="sdk-sample-card-platforms">
            <CardDetail title={t`sdkSampleCard.platforms`}>
              <div className="flex flex-row flex-wrap items-center gap-4 type-body-sm-bold text-icon-neutral">
                {Array.from(new Set(platforms)).map((platform) => {
                  const label = t(`sdkSampleCard.${platform}`)
                  const src = `/images/${
                    platform.charAt(0).toUpperCase() + platform.slice(1)
                  }Icon.svg`

                  return (
                    <div
                      key={platform}
                      className="shrink-0 flex items-center gap-1"
                      data-testid="sdk-sample-card-platform-item"
                    >
                      {platform === 'web' ? (
                        <CWIcon className="size-5" name="GlobeAltOutline" />
                      ) : (
                        <Image alt={label} height={20} src={src} width={20} />
                      )}
                      {label}
                    </div>
                  )
                })}
              </div>
            </CardDetail>
          </div>
        )}
      </div>

      {/* Features */}
      {textForFeatures && (
        <div className="w-full" data-testid="sdk-sample-card-features">
          <CardDetail title={t`sdkSampleCard.features`}>
            {textForFeatures}
          </CardDetail>
        </div>
      )}

      {/* Relevant Links */}
      <div
        className="flex flex-row flex-wrap gap-2"
        data-testid="sdk-sample-card-relevant-links"
      >
        {relevantLinks.map((link) => (
          <CWButton.Link
            key={link.url}
            data-testid="sdk-sample-card-relevant-link"
            href={link.url}
            rel="noreferrer noopener nofollow"
            target="_blank"
            variant="secondary"
          >
            {link.type && (
              <Image
                alt={link.label}
                className="mr-2 shrink-0"
                height={24}
                src={
                  link.type === 'github'
                    ? '/images/GithubBlueIcon.svg'
                    : '/images/NpmBlueIcon.svg'
                }
                width={24}
              />
            )}
            {link.label}
          </CWButton.Link>
        ))}
      </div>
    </Card>
  )
}
