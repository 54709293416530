import type { FC } from 'react'

import { ApiKeyInput } from './ApiKeyInput'
import { EntitySecretInput } from './EntitySecretInput'
// eslint-disable-next-line import/no-cycle
import { FeeInput } from './FeeInput'
import { GenericInput } from './GenericInput'
// eslint-disable-next-line import/no-cycle
import { GroupInput } from './GroupInput'
import { InputIdempotencyKey } from './InputIdempotencyKey'
import { MultiSelectInput } from './MultiSelectInput'
import { NumberInput } from './NumberInput'
import { SelectInput } from './SelectInput'
import { StaticInput } from './StaticInput'

import type { ExtendedFormSchemaType } from '../FormSchema'
import type { Field } from '../types'
import type { useForm } from '@circlefin/form'
import type { FormProps, SubComponents } from '@circlefin/form/Form'

export type InputSelectorProps = {
  Form: FC<FormProps<ExtendedFormSchemaType>> &
    SubComponents<ExtendedFormSchemaType>
  fields: Field[]
  formMethods: ReturnType<typeof useForm>['1']
}

export const InputSelector: FC<InputSelectorProps> = ({
  Form,
  fields,
  formMethods,
}) => {
  if (fields.length === 0) {
    return (
      <span className="text-success bg-success rounded-sm py-1 px-2 type-body-xs-bold">
        None needed
      </span>
    )
  }

  return fields.map((field) => {
    switch (field.type) {
      case 'apiKey':
        return (
          <ApiKeyInput
            key={field.key}
            InputComponent={Form.Input}
            field={field}
          />
        )
      case 'entitySecret':
        return (
          <EntitySecretInput
            key={field.key}
            InputComponent={Form.Input}
            field={field}
          />
        )
      case 'number':
        return (
          <NumberInput
            key={field.key}
            InputComponent={Form.Input}
            field={field}
          />
        )
      case 'multiselect':
        return (
          <MultiSelectInput
            key={field.key}
            InputComponent={Form.MultiSelectCombobox}
            field={field}
          />
        )
      case 'select':
        return (
          <SelectInput
            key={field.key}
            InputComponent={Form.Dropdown}
            field={field}
          />
        )
      case 'static':
        return (
          <StaticInput
            key={field.key}
            InputComponent={Form.Input}
            content={field.value}
            field={field}
            formMethods={formMethods}
            name={field.key}
          />
        )
      case 'group':
        return (
          <GroupInput
            Form={Form}
            InputComponent={Form.Input}
            entries={field.entries}
            field={field}
            fields={fields}
            formMethods={formMethods}
          />
        )
      case 'fee-group':
        return (
          <FeeInput
            Form={Form}
            InputComponent={Form.Input}
            entries={field.entries}
            field={field}
            fields={fields}
            formMethods={formMethods}
          />
        )
      case 'idempotencyKey':
        return (
          <InputIdempotencyKey
            key={field.key}
            InputComponent={Form.Input}
            field={field}
            formMethods={formMethods}
            name={field.key}
          />
        )
      case 'string':
        return (
          <GenericInput
            key={field.key}
            InputComponent={Form.Input}
            field={field}
            formMethods={formMethods}
          />
        )
    }
  })
}
