'use client'

import type { ReactElement } from 'react'
import { Children } from 'react'

import { Tab } from '@headlessui/react'

import { CodePanel } from '../CodePanel/CodePanel'

import { CodeGroupProvider } from './CodeGroupContext'
import { CodeGroupHeader } from './CodeGroupHeader/CodeGroupHeader'
import { useTabProps } from './useTabProps'

import type { CodePanelProps } from '../CodePanel/CodePanel'

export interface CodeGroupProps {
  /**
   * Optional title that should be displayed.
   */
  title?: string
  /**
   * Panels that should be displayed inside of the code.
   */
  children: ReactElement<CodePanelProps> | ReactElement<CodePanelProps>[]
}

interface SubElements {
  Panel: typeof CodePanel
}

export const CodeGroup: React.FC<CodeGroupProps> & SubElements = ({
  children,
  title,
}) => {
  const panels = Children.toArray(children) as ReactElement<CodePanelProps>[]
  const languages = panels.map((child) => child.props.language)
  const tabProps = useTabProps(languages)

  const containerProps = panels.length > 1 ? tabProps : {}

  const Container = panels.length > 1 ? Tab.Group : 'div'

  return (
    <div className="py-2">
      <div className="w-full rounded-sm border border-neutral-subtle bg-white">
        <CodeGroupProvider>
          <Container {...containerProps}>
            <CodeGroupHeader
              selectedIndex={tabProps.selectedIndex}
              setSelectedIndex={tabProps.onChange}
              title={title}
            >
              {children}
            </CodeGroupHeader>
            <div className="w-full">
              {panels.length > 1 ? (
                <Tab.Panels>
                  {Children.map(panels, (panel) => (
                    <Tab.Panel>
                      <CodePanel
                        code={panel.props.code}
                        language={panel.props.language}
                        title={panel.props.title || panel.props.language}
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              ) : (
                <CodePanel
                  code={panels[0].props.code}
                  language={panels[0].props.language}
                  title={panels[0].props.title}
                />
              )}
            </div>
          </Container>
        </CodeGroupProvider>
      </div>
    </div>
  )
}
CodeGroup.Panel = CodePanel
