'use client'

import { type FC, type PropsWithChildren } from 'react'

import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'

export interface FeatureFlaggedSectionProps {
  /**
   * Flag that should be checked for section to render.
   */
  flag: Parameters<typeof useFeatureFlag>[0]
}

export const FeatureFlaggedSection: FC<
  PropsWithChildren<FeatureFlaggedSectionProps>
> = ({ flag, children }) => {
  try {
    const flagValue = useFeatureFlag(flag)

    return typeof flagValue === 'boolean' && flagValue ? children : null
  } catch {
    return null
  }
}
