import { useEffect } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import type { Field } from './../types'

export interface FormCompProps {
  onSubmit: (data: Record<string, unknown>) => Promise<void>
  fields: Field[]
  identifier: string
}

export const FormComp: React.FC<FormCompProps> = ({
  fields,
  onSubmit,
  identifier,
}) => {
  const { t } = useTranslation('common')
  const [Form, { setValue }] = useForm<{ [key: string]: unknown }>({
    schema: y.object().shape({
      apiKey: y.string().matches(/TEST_API_KEY:[a-z0-9]*:[a-z0-9]*/gm, {
        message: 'Please make sure to provide a valid Testnet API Key',
      }),

      entitySecret: y.string().matches(/^[a-z0-9]{64,64}$/gm, {
        message: 'Invalid Entity Secret',
      }),
      walletId: y.string().uuid('Invalid Wallet ID'),
      walletSetId: y
        .string()
        .matches(
          /^[0-9A-F]{8}-[0-9A-F]{4}-[7|5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
          {
            message:
              'Invalid Wallet Set ID. Use the Wallet Set created in the last step.',
          },
        ),
      tokenId: y.string().uuid('Invalid UUID'),
      amount: y
        .number()
        .typeError('Amount must be a number')
        .positive('Amount must be a positive number'),
      destinationAddress: y
        .string()
        .lowercase()
        .matches(/(\b0x[a-fA-F0-9]{40}\b)/g, {
          message: 'Invalid blockchain address',
        }),
      count: y
        .number()
        .typeError('Count must be a number')
        .positive('Count must be a positive number'),
      name: y
        .string()
        .lowercase()
        .min(1, 'Name must be at longer than 1 character')
        .max(50, 'Name must be shorter than 50 characters'),
      userId: y
        .string()
        .lowercase()
        .min(5, 'User ID must be longer than 5 characters')
        .max(50, 'User ID must be shorter than 50 characters'),
      userWalletId: y
        .string()
        .matches(
          /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
          {
            message:
              'Invalid Wallet ID. Please use the Wallet ID from Check Wallet Status response',
          },
        ),
    }),
    defaultValues: fields.reduce((values, field) => {
      return {
        ...values,
        [field.key]: field.value,
      }
    }, {}),
  })

  useEffect(() => {
    fields.forEach((item) => {
      setValue(item.key, item.value)
    })
  }, [fields, setValue])

  return (
    <Form data-testid={`${identifier} Form`} onSubmit={onSubmit}>
      <button
        className="absolute right-0 top-0 mr-5 mt-2 rounded-sm px-4 py-1 text-sm text-blue-25 font-circular-bold hover:bg-white/10"
        type="submit"
      >
        Execute
      </button>
      <div className="w-full space-y-4 px-6 py-4">
        {fields.length === 0 ? (
          <span className="inline-block w-full text-center text-sm">
            No input parameters required
          </span>
        ) : (
          fields.map((field) => {
            switch (field.type) {
              case 'apiKey':
                return (
                  <Form.Input
                    className="w-full"
                    name="apiKey"
                    placeholder="e.g. TEST_API_KEY:xxxxxx:xxxxxx"
                    {...field}
                  />
                )
              case 'entitySecret':
                return (
                  <Form.Input
                    className="w-full"
                    name="entitySecret"
                    {...field}
                  />
                )
              case 'number':
                return (
                  <Form.Input
                    className="w-full"
                    name={field.key}
                    placeholder="e.g. 1"
                    {...field}
                    type="number"
                  />
                )
              case 'multiselect':
                return (
                  <Form.MultiSelectCombobox
                    items={field.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    locale={{
                      clearButtonLabel: t`forms.multiSelect.clearButtonLabel`,
                      noResultsMessage: t`forms.multiSelect.noResultsMessage`,
                      noItemsMessage: t`forms.multiSelect.noItemsMessage`,
                    }}
                    name={field.key}
                    // eslint-disable-next-line react/jsx-no-bind
                    onInputChange={() => undefined}
                    searchTerm=""
                    {...field}
                  />
                )
              case 'select':
                return (
                  <Form.Dropdown
                    key={field.key}
                    items={field.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    label={field.label}
                    name={field.key}
                  />
                )
              case 'string':
                return (
                  <Form.Input className="w-full" name={field.key} {...field} />
                )
            }
          })
        )}
      </div>
    </Form>
  )
}
