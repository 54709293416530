'use client'

import { Content } from './Content/Content'

export interface CopyParams {
  /**
   * Description that will be displayed next to the isolated result.
   */
  description: string
  params: {
    /**
     * Title that will be applied to the CodePanel.
     */
    title: string
    /**
     * Path of an element that should be isolated for a better user experience.
     */
    path: string
    /**
     * Key to store the result.
     */
    key?: string
  }[]
}

export interface PlaygroundProps {
  /**
   * API url for specific playground.
   */
  url: string
  /**
   * Title that should be displayed.
   */
  title: string
  /**
   * Config for isolated copy values.
   */
  copy?: CopyParams
  /**
   * Specify whether the input params are different to the real endpoints params.
   */
  diffInParams?: boolean
}

export const Playground: React.FC<PlaygroundProps> = ({
  title,
  url,
  copy,
  diffInParams,
}) => {
  return (
    <div className="pb-6">
      <div className="relative rounded-sm bg-white shadow-sm">
        <div className="flex items-center justify-between gap-x-4 rounded-t-sm border-x border-t border-blue-400 bg-blue-400 pb-2 pl-6 pr-4 pt-3 text-sm text-blue-25">
          <div>
            <strong className="mr-1 font-circular-bold">Try it out:</strong>
            <span>{title}</span>
          </div>
        </div>
        <div className="rounded-b-sm border border-neutral-subtle">
          <Content copy={copy} url={url} />
        </div>
      </div>
      {diffInParams ? (
        <p className="mt-2 text-sm italic font-circular-regular">
          Note: The input parameters of this component differ from those of the
          actual endpoint. For instance, we automatically generate the{' '}
          <strong>Entity Secret Ciphertext</strong> to simplify the process and
          ensure a better experience for you.
        </p>
      ) : null}
    </div>
  )
}
