'use client'

import type { PropsWithChildren } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import { capitalize } from '@shared/utils'
import classNames from 'classnames'
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism'

import { useCodeGroupContext } from '../CodeGroup/CodeGroupContext'
import { LANGUAGE, mapLanguage } from '../languages'

import { CopyToClipboard } from './CopyToClipboard/CopyToClipboard'

import type { LanguageType } from '../languages'

export interface CodePanelProps {
  /**
   * Code that should be displayed.
   */
  code: string
  /**
   * Language that should be used for highlighting.
   */
  language: LanguageType
  /**
   * This optional title will only be displayed if this CodePanel is not embedded in a CodeGroup.
   */
  title?: string
  /**
   * Specify whether the header should be hidden.
   */
  embedded?: boolean
  identifier?: string
}

export const CodePanel: React.FC<PropsWithChildren<CodePanelProps>> = ({
  code,
  language,
  title,
  embedded,
  identifier,
}) => {
  const ctx = useCodeGroupContext()
  const isGrouped = Boolean(ctx)

  return (
    <div className={isGrouped || embedded ? '' : 'py-2'}>
      <div
        className={classNames({
          'bg-white border border-neutral-subtle rounded-sm':
            !isGrouped && !embedded,
        })}
      >
        <div className="w-full">
          {!isGrouped && !embedded && (
            <div className="flex items-center justify-between gap-x-4 border-b border-b-black-50 pl-4 pr-2">
              {title && (
                <h4 className="mr-auto shrink-0 truncate pb-2 pl-2 pt-3 text-sm text-black-700 font-circular-bold">
                  {title}
                </h4>
              )}
              <span className="border-b-2 border-black-900 px-2 pb-2 pt-3 text-sm text-black-900 font-circular-book focus:outline-none">
                {LANGUAGE[language] ?? capitalize(language || 'text')}
              </span>
            </div>
          )}
          <div
            className="group relative"
            data-testid={`${identifier} Code Panel`}
          >
            <SyntaxHighlighter
              CodeTag={Code}
              PreTag={Pre}
              language={mapLanguage(language)}
              style={ghcolors}
            >
              {code}
            </SyntaxHighlighter>
            <CopyToClipboard code={code} />
          </div>
        </div>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Code: React.FC<any> = ({ style, ...props }) => {
  return <code {...props} />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Pre: React.FC<any> = ({ style, ...props }) => (
  <pre
    className="overflow-x-auto px-6 py-4 text-xs font-circular-light"
    {...props}
  />
)
