import type { FC, PropsWithChildren } from 'react'

import classNames from 'classnames'

import { CWButton } from '../ComponentsWeb/Button'

import type { ButtonProps } from '@circlefin/components/lib/Button'

/**
 * Button components that wraps the ComponentsWeb Button and adds the type-label-md class to make label text uppercase.
 */
export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <CWButton {...props} className={classNames('type-label-md', className)}>
      {children}
    </CWButton>
  )
}
