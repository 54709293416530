'use client'

import { useCallback, useState } from 'react'

import { useConfig } from '@features/common.hooks/useConfig'

import { RequestTemplateCode } from './RequestTemplateCode'
import { RequestTemplateHeader } from './RequestTemplateHeader'

import type { RequestConfig } from './types'

export interface RequestTemplateProps {
  config: RequestConfig
  title?: string
}
export interface Target {
  key: string
  name: string
  clients: string[]
}

const targets: Target[] = [
  {
    key: 'go',
    name: 'Go',
    clients: ['native'],
  },
  {
    key: 'node',
    name: 'NodeJS',
    clients: ['fetch', 'axios', 'native', 'request', 'unirest'],
  },
  {
    key: 'java',
    name: 'Java',
    clients: ['asynchttp', 'nethttp', 'okhttp', 'unirest'],
  },
  {
    key: 'javascript',
    name: 'JavaScript',
    clients: ['axios', 'fetch', 'jquery', 'xhr'],
  },
  {
    key: 'kotlin',
    name: 'Kotlin',
    clients: ['okhttp'],
  },
  {
    key: 'php',
    name: 'PHP',
    clients: ['curl', 'guzzle', 'http1', 'http2'],
  },
  {
    key: 'python',
    name: 'Python',
    clients: ['python3', 'requests'],
  },
  {
    key: 'ruby',
    name: 'Ruby',
    clients: ['native'],
  },
  {
    key: 'shell',
    name: 'Shell',
    clients: ['curl', 'httpie', 'wget'],
  },
  {
    key: 'swift',
    name: 'Swift',
    clients: ['nsurlsession'],
  },
]

export const RequestTemplate: React.FC<RequestTemplateProps> = ({
  config,
  title,
}) => {
  const {
    urls: { api: apiUrl },
  } = useConfig()
  const [selectedTargetKey, setSelectedTargetKey] = useState('node')
  const [selectedClientKey, setSelectedClientKey] = useState<
    string | undefined
  >()

  const selectedTarget =
    targets.find(({ key }) => key === selectedTargetKey) ?? targets[1]

  const selectedClient = selectedClientKey ?? selectedTarget.clients[0]

  const handleTargetSelection = useCallback(
    (targetKey: string) => {
      if (targetKey !== selectedTargetKey) {
        setSelectedClientKey(undefined)
      }
      setSelectedTargetKey(targetKey)
    },
    [selectedTargetKey],
  )

  return (
    <div className="pb-6">
      <div className="w-full rounded-sm border border-neutral-subtle bg-white">
        <RequestTemplateHeader
          selectedClient={selectedClient}
          selectedTarget={selectedTarget}
          setSelectedClientKey={setSelectedClientKey}
          setSelectedTargetKey={handleTargetSelection}
          targets={targets}
          title={title ?? 'Request Example'}
        />
        <RequestTemplateCode
          apiUrl={apiUrl}
          config={config}
          selectedClient={selectedClient}
          selectedTarget={selectedTarget}
        />
      </div>
    </div>
  )
}
